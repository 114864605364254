import React, { useEffect, useRef } from 'react'
import { Parallax, useParallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import Contact from '../../component/contact';

export default function Service() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Our Services</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Services</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'>OUR SERVICES</h3>
                        <h2 className='heading'>Let’s Talk Digital Marketing</h2>
                        {/* <p className='paragraph'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamco.</p> */}

                    </div>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/telecom">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/telecom.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Telecom Services</h4>
                                    <p className=''>We have the expertise and experience to install, support and manage in Telecom operations and applications.</p>
                                    <Link to='/services/telecom'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to={"/services/solar"}>
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/solar.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Solar Services</h4>
                                    <p className=''>We are professionally managed company in the domain of solar and renewable energy solutions in India.</p>
                                    <Link to='/services/solar'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to={"/services/fiber-optics"}>
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/fiber/fiber-1.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Fiber Optics Services</h4>
                                    <p className=''>We are professionally managed company in the domain of solar and renewable energy solutions in India.</p>
                                    <Link to='/services/fiber-optics'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to={"/services/wi-fi"}>
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/wi-fi.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Wi-Fi Services</h4>
                                    <p className=''>We are expert to create a perfect wi-fi network and offers other wi-fi services as well.</p>
                                    <Link to='/services/wi-fi'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/web-development">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/website.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Web Development</h4>
                                    <p className=''>Website development is our art – it's about envisioning, coding, and organizing the symphony of layout, content, and functionality.</p>
                                    <Link to='/services/web-development'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/digital-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/it.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Digital Marketing</h4>
                                    <p className=''>Solutions designed to address the unique challenges and dynamics of rural consumer segments.</p>
                                    <Link to='/services/digital-marketing'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Contact />
        </>
    )
}
