import React, { useEffect, useRef } from 'react'
import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
export default function TradeMarketing() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Solar Services</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item"><Link to="/services">Services</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Solar Service</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='service-detail-left-side'>
                                <h3 className='sub-heading'> OUR SERVICES</h3>
                                <h2 className='heading'>Solar Service</h2>
                                <p className=''>
                                    <strong>We are professionally managed company in the domain of solar and renewable energy solutions in India.</strong>
                                </p>
                                <p>At <b>OZETEL NETWORK PRIVATE LIMITED</b>, we specialize in delivering high-quality solar solutions tailored to meet your energy needs. From residential to commercial projects, our expert team provides comprehensive services including solar panel installation, maintenance, and energy efficiency consultations. With a commitment to sustainability, we aim to empower businesses and homeowners to harness the power of solar energy for a greener tomorrow. Explore our innovative technologies and reliable services designed to maximize your energy savings and environmental impact. Join us in leading the way towards a cleaner and more sustainable future with <b>OZETEL NETWORK PRIVATE LIMITED</b> Solar Services.</p>
                                <div className='three-images-container'>
                                    <div className='first-img-box'>
                                        <img src={require('../../assets/images/services/trade/1.jpg')} />
                                    </div>
                                    <div className='second-img-box'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/trade/2.jpeg')} />
                                        </div>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/trade/3.png')} />
                                        </div>
                                    </div>
                                </div>
                                <p className=''>
                                    <strong>Solar System Installation</strong>
                                </p>
                                <p>We have installed more then 500+ Solar System in Village & City. We are still working in 2 State. </p>
                                <p className=''>
                                    <strong>Solar Street Lamp Installation</strong>
                                </p>
                                <p>We have installed more then 1000+ Solar Street Lamp in UPE & UPW. We are still working in 3 State. </p>
                                <p className=''>
                                    <strong>Support & Maintenance</strong>
                                </p>
                                <p>We have not only installed Solar equipment but also provide the support and maintenance service . We are giving support in all over India. </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <ServiceInclude />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
