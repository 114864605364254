
import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Pagination, Autoplay, Navigation, Keyboard } from 'swiper/modules';
// import WOW from 'wowjs';

import CountUp from 'react-countup';
import { Button, Label } from 'reactstrap';
import { Parallax, useParallax } from 'react-scroll-parallax';

import { Link } from 'react-router-dom';
import FeaturedProject from '../component/FeaturedProject';
export default function Client() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Clients</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Client</li>
                    </ol>
                </nav>
            </section>


            <section className='padding-left-right top bottom about-section-2'>
                <div className='container-fluid'>
                    {/* <h4 className='heading text-center'>TRUSTED BY THE CLIENTS</h4> */}
                    <div className='brand-logo'>
                        <div className='brand_logo-slider row'>
                            <div className='col-lg-3  col-md-4 col-sm-6 col-12'>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/vedang-logo.png')} alt='vedang' />
                                </div>
                            </div>
                            <div className='col-lg-3  col-md-4 col-sm-6 col-12'>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-ATS.jpg')} alt='ats' />
                                </div>
                            </div>
                            <div className='col-lg-3  col-md-4 col-sm-6 col-12'>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-tejas.jpg')} alt='tejas' />
                                </div>
                            </div>
                            <div className='col-lg-3  col-md-4 col-sm-6 col-12'>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-bbnl.jpg')} alt='delhi' />
                                </div>
                            </div>
                            <div className='col-lg-3  col-md-4 col-sm-6 col-12'>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-bsnl.jpg')} alt='delhi' />
                                </div>
                            </div>
                            <div className='col-lg-3  col-md-4 col-sm-6 col-12'>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-mainfold.jpg')} alt='delhi' />
                                </div>
                            </div>
                            <div className='col-lg-3  col-md-4 col-sm-6 col-12'>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-huber-shuner.jpg')} alt='delhi' />
                                </div>
                            </div>
                            <div className='col-lg-3  col-md-4 col-sm-6 col-12'>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-Ericsson-India.jpg')} alt='delhi' />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
