import React from 'react'
import './contact.css';

function contact() {
    return (
        <div>
            <div className="container">
                <span className="big-circle"></span>
                <img src="img/shape.png" className="square" alt="" />
                <div className="form">
                    <div className="contact-info">
                        <h3 className="title">Let's get in touch</h3>
                        <p className="text">
                            Thank you for your interest in <strong>OZETEL NETWORK</strong> services. Please provide the following information about your business needs. This will help us serve you better by directing your request to the appropriate person.
                        </p>
                        <div className="info">
                            <div className="information">
                                <p><i className="fas fa-map-marker-alt"></i> &nbsp; &nbsp;HN-171, Flat No-2, Second Floor,Sultanpur, Delhi-110030</p>
                            </div>
                            <div className="information">

                                <p><i className="fas fa-envelope"></i> &nbsp; &nbsp;chandan@ozetelnetwork.com</p>
                            </div>
                            <div className="information">

                                <p><i className="fas fa-phone"></i>&nbsp;&nbsp;9149274486</p>
                            </div>
                        </div>

                        <div className="social-media">
                            <p>Connect with us :</p>
                            <div className="social-icons">
                                <a href="#">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a href="#">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a href="#">
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <a href="#">
                                    <i className="fab fa-linkedin-in"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="contact-form">
                        <span className="circle one"></span>
                        <span className="circle two"></span>

                        <form>
                            <h3 className="title">Contact us</h3>
                            <div className="input-container">
                                <input type="text" placeholder="Enter name" className="input" />

                            </div>
                            <div className="input-container">
                                <input type="email" placeholder='Enter email' className="input" />
                            </div>
                            <div className="input-container">
                                <input type="tel" placeholder='Enter mobile' className="input" />
                            </div>
                            <div className="input-container textarea">
                                <textarea placeholder='Enter message' className="input"></textarea>
                            </div>
                            <input type="submit" value="Submit" className="btn" />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default contact