import React, { useEffect, useRef } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';

export default function OutofHome() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Wi-Fi Services</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item"><Link to="/services">Services</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Wi-Fi</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='service-detail-left-side'>
                                <h3 className='sub-heading'> OUR SERVICES</h3>
                                <h2 className='heading'>Wi-Fi</h2>
                                <p className=''>
                                    <strong>We are expert to create a perfect wi-fi network and offers other wi-fi services as well.</strong>
                                </p>
                                <p>Harness the power of the sun with <b>OZETEL NETWORK PRIVATE LIMITED</b> Solar Services. Specializing in renewable energy solutions, we offer tailored solar installations for residential, commercial, and industrial clients. Our expert team ensures seamless integration from design to execution, utilizing cutting-edge technology to maximize energy efficiency and cost savings. Whether you're looking to reduce your carbon footprint or achieve energy independence, our comprehensive services include site assessment, installation, maintenance, and ongoing support. Join us in paving the way towards a sustainable future with reliable solar solutions that empower your business or home. Discover the benefits of solar with <b>OZETEL NETWORK PRIVATE LIMITED</b> today.</p>
                                <div className='three-images-container'>
                                    <div className='first-img-box'>
                                        <img src={require('../../assets/images/services/out-of-home/1.webp')} />
                                    </div>
                                    <div className='second-img-box'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/out-of-home/2.png')} />
                                        </div>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/out-of-home/3.png')} />
                                        </div>
                                    </div>
                                </div>
                                <p className=''>
                                    <strong>GPON</strong>
                                </p>
                                <p>We have installed more then 5000+ GPON in Village & City. We are still working in 7 State.</p>

                                <p className=''>
                                    <strong>Wi-Fi Hotspot Installation</strong>
                                </p>
                                <p>We have installed more then 5000+ wi-fi hotspot system in Village & City. We are still working in 7 State.</p>
                                <p className=''>
                                    <strong>Wi-Fi Network Design</strong>
                                </p>
                                <p>We design wi-fi network for the school, college, city, village, hospital, home and office. We design wifi network in a such way so that user can get fast response.</p>
                                <p className=''>
                                    <strong>Support & Maintenance</strong>
                                </p>
                                <p>We have not only installed wi-fi equipment but also provide the support and maintenance service . We are giving support in all over India.</p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <ServiceInclude />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
