import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap'



export default function Footer() {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [isDisclaimer, setIsDisclaimer] = useState(false);
  useEffect(() => {
    const updateScrollPercentage = () => {
      const scrollPx = document.documentElement.scrollTop;
      const winHeightPx =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (scrollPx / winHeightPx) * 100;

      setScrollPercentage(scrolled);
    };

    window.addEventListener('scroll', updateScrollPercentage);

    return () => {
      window.removeEventListener('scroll', updateScrollPercentage);
    };
  }, []);

  useEffect(() => {
    let time = null;
    const getData = async () => {
      const item = await localStorage.getItem('policy');
      console.log('policy from storage', item);
      if (!item)
        time = setTimeout(() => {
          setIsDisclaimer(true)
        }, 3000);
    }
    getData();
    return () => clearTimeout(time);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setIsDisclaimer(true);
      } else {
        setIsDisclaimer(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleOkayDisclaimer = () => {
    setIsDisclaimer(false);
    localStorage.setItem('policy', 'agreed');
  }

  return (
    <footer className='footer top padding-left-right'>

      <div className="container-fluid p-0">
        <div className='row'>
          <div className="footer-col col-lg-3 col-md-6">
            {/* <div className='footer-logo'>
              <img src={require("../assets/images/logo-w.png")} />
            </div> */}
            <h4>OZETEL NETWORK PRIVATE LIMITED</h4>
            <p><i class="fa fa-map-marker" aria-hidden="true"></i> HN-171, Flat No-2, Second Floor,Sultanpur, Delhi-110030</p>
            <ul className='mb-4'>
              <li><a href="tel:9149274486">Phone: +91- 9149274486</a></li>
              <li><a href="#">Email: chandan@ozetelnetwork.com</a></li>
            </ul>
            <ul>
              {/* <li><a href="mailto:contact@thebrandstreet.co">contact@thebrandstreet.co</a></li> */}
            </ul>
          </div>
          <div className="footer-col col-lg-3 col-md-6">
            <h4>Quick Links</h4>
            <ul>
              <li><a href="/">Home</a></li>
              <li><Link to="/career">Career</Link></li>
              <li><Link to="/clients">Clients</Link></li>
              <li><Link to="/contact-us">Contact</Link></li>

            </ul>
          </div>
          <div className="footer-col col-lg-3 col-md-6">
            <h4>Services</h4>
            <ul>
              <li><Link to="/services/telecom">Telecom Services</Link></li>
              <li><Link to="/services/out-of-home ">Wi-Fi Services</Link></li>
              <li><Link to="/services/digital-marketing">Solar Services</Link></li>
              <li><Link to="/services/web-development">Web Development</Link></li>
              <li><Link to="/services/digital-marketing">Digital Marketing</Link></li>
              <li><Link to="/services/fiber-optics">Fiber Optics</Link></li>
            </ul>
          </div>
          <div className="footer-col col-lg-3 col-md-6">

            <div class="col-sm-12">
              <h4>Social Links</h4>
              {/* <form className='footer-form'>
                <input type="email" placeholder="Email" className="inptEmail" required />
                <button className='btn footer-btn btn-class' style={{ borderRadius: '20px' }}>Subscribe<span>Subscribe</span></button>
                <p className='mt-0 pt-0'>* we respact your privacy</p>
              </form> */}
            </div>

            <div className="social-links">
              <li>
                <a href="#" target='_blank'><i className="fa-brands fa-facebook-f"></i></a>
              </li>
              <li>
                <a href="#" target='_blank'><i className="fa-brands fa-instagram"></i></a>
              </li>
              <li>
                <a href="#" target='_blank'><i className="fa-brands fa-linkedin-in"></i></a>
              </li>
              <li>
                <a href="#" target='_blank'><i className="fa-brands fa-twitter"></i></a>
              </li>
            </div>
          </div>
        </div>


      </div>
      <div className='container-fluid'>
        <div className='footer-copyright row'>
          <div className='col-lg-4 p-0'>
            <div className='copy-right-p'>
            </div>
          </div>
          <div className='col-lg-4 p-0'>
            <div className='privacy-policy'>
              <p>  &copy; 2024 OZETEL NETWORK PRIVATE LIMITED </p>

            </div>
          </div>
          <div className='col-lg-4 p-0'>
            <div className='website-link'>
            </div>
          </div>
        </div>
      </div>

      <div class="progress-circle-container">

        <svg viewBox="0 0 36 36" className="circular-chart">
          <path
            className="circle-bg"
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle"
            strokeDasharray={`${scrollPercentage}, 100`}
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>

        <div className="uparrow">
          <a href="#"><i className="fa fa-arrow-up"></i></a>
        </div>

      </div>
      {/* <div className={isDisclaimer ? 'cookie-section active' : 'cookie-section'}>
        <div>
          <h4>Agree to cookies</h4>
          <p>"Brand Street Integrated is a leading marketing agency based in Gurgaon, specializing in innovative branding solutions tailored to elevate your business presence. With a strategic blend of creativity and analytics, we craft compelling narratives that resonate with your audience, driving impactful results."</p>
        </div>
        <button onClick={handleOkayDisclaimer}>OK, I got it</button>
      </div> */}
    </footer>
  )
}


