import React from 'react'
import { Link } from 'react-router-dom'

export default function ServiceInclude() {
    return (
        <div className='right-section-service'>
            <h4 className='sub-heading'>Services</h4>

            <ul>
                <li><i aria-hidden="true" class="fas fa-circle"></i> <Link to={"/services/telecom"}>Telecom Services</Link></li>
                <li><i aria-hidden="true" class="fas fa-circle"></i> <Link to={"/services/solar"}>Solar Services</Link></li>
                <li><i aria-hidden="true" class="fas fa-circle"></i> <Link to={"/services/wi-fi"}>Wi-Fi Services</Link></li>
                <li><i aria-hidden="true" class="fas fa-circle"></i> <Link to={"/services/fiber-optics"}>Fiber Optics</Link></li>
                <li><i aria-hidden="true" class="fas fa-circle"></i> <Link to={"/services/web-development"}>Web Development</Link></li>
                <li><i aria-hidden="true" class="fas fa-circle"></i> <Link to={"/services/digital-marketing"}>Digital Marketing</Link></li>
            </ul>
        </div>
    )
}
