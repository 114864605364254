import React from 'react'
import { Link } from 'react-router-dom'

export default function FeaturedProject() {
    return (
        <section className='bottom padding-left-right featured-section'>
            <div className='header-title mb-40px'>
                <div>
                    <h6 className='sub-heading wow fadeInUp'>OUR PORTFOLIO</h6>
                    <h2 className='heading wow fadeInUp'>Featured Projects</h2>
                </div>
                {/* <Link to='/gallery' className='wow fadeInUp button_effect'><div className='line_border'></div>Explore All Portfolio <i class="fa-solid fa-arrow-right"></i></Link> */}
            </div>
            <div class="marquee right">
                <div class="marquee-content ">


                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/1.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>

                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/2.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>

                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/3.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>
                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/4.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>
                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/5.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>
                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/6.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>

                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/1.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>

                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/2.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>

                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/3.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>
                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/4.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>
                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/5.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>
                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/6.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>
                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/1.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>

                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/2.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>

                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/3.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>
                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/4.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>
                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/5.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>
                    <div class="marquee-item">
                        <img src={require('../assets/images/gallery/ogtech/6.jpg')} style={{ width: 350, height: 240, borderRadius: 5 }} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}
