import React, { useEffect, useRef } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
export default function TelecomServices() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>TELECOM SERVICES</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item"><Link to="/services">Services</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Telecom</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='service-detail-left-side'>
                                <h3 className='sub-heading'> OUR SERVICES</h3>
                                <h2 className='heading'>Telecom</h2>
                                <p className=''>
                                    <strong>"We have the expertise and experience to install, support and manage in Telecom operations and applications."</strong>
                                </p>
                                <p>Our telecom services encompass a diverse array of cutting-edge technologies and solutions vital for today’s interconnected world. From robust voice communication over landline networks to seamless mobile and data services via cellular and broadband networks, we empower global connectivity. Our commitment to innovation ensures reliable, high-speed internet access through DSL, cable, and fiber optics. Whether enhancing business operations or enabling personal connections, our telecom solutions are tailored to meet diverse needs efficiently. Trust us to drive your digital transformation forward with secure, scalable, and future-ready telecom services.</p>
                                <div className='three-images-container'>
                                    <div className='first-img-box'>
                                        <img src={require('../../assets/images/services/rural/1.jpg')} />
                                    </div>
                                    <div className='second-img-box'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/rural/2.jpg')} />
                                        </div>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/rural/3.jpg')} />
                                        </div>
                                    </div>
                                </div>
                                {/* <h3 className='sub-heading'>This is how we work</h3> */}
                                <p className=''>
                                    <strong> 4G&5G Installation</strong>
                                </p>
                                <p> We have installed more then 1000+ 4G & 5G in all over india in 4G & 5G Project. We are still working in 5 State.</p>

                                <p className=''>
                                    <strong>Microwave Installation</strong>
                                </p>
                                <p>We have installed more then 1000+ Microwave in all over india in Microwave Project. We are still working in 5 State.</p>
                                <p className=''>
                                    <strong>SSCVT-I Installation</strong>
                                </p>
                                <p>We have installed more then 1000+ SSCVT-I in all over india in SSCVT-I Project. We are still working in 5 State.</p>
                                <p className=''>
                                    <strong>CPAN </strong>
                                </p>
                                <p>We have installed more then 1000+ CPAN in all over india in CPAN Project. We are still working in 5 State.</p>
                                <p className=''>
                                    <strong>DWDM </strong>
                                </p>
                                <p>We have installed more then 1000+ DWDM in all over india in DWDM Project. We are still working in 5 State.</p>
                                <p className=''>
                                    <strong>OTN </strong>
                                </p>
                                <p>We have installed more then 1000+ OTN in all over india in OTN Project. We are still working in 5 State.</p>
                                <p className=''>
                                    <strong>MPLS </strong>
                                </p>
                                <p>We have installed more then 1000+ MPLS in all over india in MPLS Project. We are still working in 5 State.</p>
                                <p className=''>
                                    <strong>Support & Maintenance</strong>
                                </p>
                                <p>We not only install telecom equipment but also offer comprehensive support and maintenance services across India. Our expert team ensures seamless operation and timely assistance, addressing issues swiftly and efficiently. Whether it’s routine upkeep or urgent repairs,
                                    we are dedicated to keeping your telecom systems running smoothly nationwide.</p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <ServiceInclude />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
