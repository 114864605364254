
import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import { Link } from 'react-router-dom';
import { Parallax, useParallax } from 'react-scroll-parallax';

export default function VisionAndMission() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>VISION & MISSION</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Vision & Mission</li>
                    </ol>
                </nav>
            </section>

            <section className='top privacy_po bottom padding-left-right about-section-1 '>
                <div className='container-fluid'>
                    <div className='row'>


                        <h3 style={{ color: '#444449', fontWeight: '200' }}>
                            Our Vision
                        </h3>

                        <p>
                            OZETEL NETWORK will start to provide internet service from a block and progressively grow in all the 6600 blocks
                            of the country. OZETEL NETWORK will cover all 8000+ cities and 600,000+ revenue villages of the country by 2020.
                            Its manufactured products will suit all countries having deficit electrical power. It will latch customers in
                            ISM bands wirelessly or over Optical Fiber Cable/ FTTH initially to provide 4th and coming 5th generation Smartphones.
                            Its mission is to make internet services affordable to every section of the country and bring the unconnected with OZETEL NETWORK
                            wherever connectivity requires people to people or people to machine or machine to machine.
                        </p>

                        <h3 style={{ color: '#444449', fontWeight: '200' }}>
                            Our Mission

                        </h3>

                        <p>
                            OZETEL NETWORK Inspired from the vision of Digital India and to become part of the growth of the country. Providing Transmission support to the Digitization of TV contents delivery to make it viable up to every household in cities and rural.                        </p>

                        <p> OZETEL NETWORK will manufacture innovative communication and handheld devices and provide network solutions to fit within the budget of citizens of developing countries.OZETEL NETWORK will promote green telecom by adapting solar powered energy efficient low power telecom devices while providing services.</p>
                    </div>
                </div>
            </section>

        </>
    )
}
