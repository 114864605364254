
import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import { Link } from 'react-router-dom';
import { Parallax, useParallax } from 'react-scroll-parallax';

export default function Overview() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Overview</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Overview</li>
                    </ol>
                </nav>
            </section>

            <section className='top privacy_po bottom padding-left-right about-section-1 '>
                <div className='container-fluid'>
                    <div className='row'>


                        <h3 style={{ color: '#444449', fontWeight: '200' }}>
                            Overview
                        </h3>

                        <p>
                            OZETEL NETWORK Inspired from the vision of Digital India and to become part in the growth of the country.
                        </p>

                        <p><b>OZETEL NETWORK PRIVATE LIMITED</b>, CIN: U64201DL2015PTC287279, a company incorporated under the Companies Act, 1956 as amended in 2013 and having its registered office at <b>HN-171, Flat No-2, Second Floor,Sultanpur, Delhi-110030</b> (hereinafter referred to <b>OZETEL NETWORK PRIVATE LIMITED</b>, which expression shall unless repugnant to the context or meaning thereof mean and include its successors and permitted assigns.</p>
                        <p>We are presently equipped with the 20+ employees on role and 150+ work force on need basis. Our team has spread all over india. Presently 50 teams are working on site based on work allocations. Number of teams at sites varies based on work allocations to us. </p>
                        <p>We are a group of professionals specifically Engineering Graduates in Telecom Engineering and Electrical Engineering. A dedicated IT team is working as support for technical support and real time documentation. Experienced engineers & diploma holders are involved in installation and survey work all over india.</p>

                        <h4>Our company information is given below:</h4>
                        <p><b>CIN:</b> U61900DL2024PTC430705</p>
                        <p><b>GSTIN:</b> 07AAECO3792N1ZU</p>
                        <p><b>MSME:</b> UDYAM-DL-08-0079790</p>
                    </div>
                </div>
            </section>

        </>
    )
}
