import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Pagination, Autoplay, Navigation, Keyboard } from 'swiper/modules';
// import WOW from 'wowjs';

import CountUp from 'react-countup';
import { Button, Label } from 'reactstrap';
import { Parallax, useParallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import FeaturedProject from '../component/FeaturedProject';
import Contact from '../component/contact';
export default function Home() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className='banner-slider'>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    navigation={false}
                    modules={[Autoplay]}
                    className=" home-slider"
                >
                    <SwiperSlide>

                        <div className='banner-section '>

                            <img src={require('../assets/images/banner.jpg')} alt='banner' className='web-banner' />
                            <div className='banner-content padding-left-right'>

                                <div className='dot-img'>
                                    <img src={require('../assets/images/white-dot.png')} alt='white-dot' />
                                </div>
                                <h6 className='sub-heading'>OZETEL NETWORK PRIVATE LIMITED </h6>
                                <h1 className='heading'>"A WIN WAY<br />
                                    TO GO GREEN WITH<br />
                                    SOLAR ENERGY."</h1>

                            </div>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='banner-section '>

                            <img src={require('../assets/images/banner-2.jpg')} className='web-banner' alt='banner-2' />
                            <div className='banner-content padding-left-right'>
                                <div className='dot-img'>
                                    <img src={require('../assets/images/white-dot.png')} alt='white-dot' />
                                </div>
                                <h6 className='sub-heading'>OZETEL NETWORK PRIVATE LIMITED </h6>
                                <h1 className='heading'>"We want to world<br />
                                    full of laughter and<br />
                                    joy for it's children."</h1>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='banner-section '>

                            <img src={require('../assets/images/banner-3.jpg')} className='web-banner' alt='banner-2' />
                            <div className='banner-content padding-left-right'>
                                <div className='dot-img'>
                                    <img src={require('../assets/images/white-dot.png')} alt='white-dot' />
                                </div>
                                <h6 className='sub-heading'>OZETEL NETWORK PRIVATE LIMITED </h6>
                                <h1 className='heading'>"SUPPLY, INSTALL<br />
                                    AND MAINTAIN TELECOM EQUIPMENT"<br />
                                </h1>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='banner-section '>

                            <img src={require('../assets/images/banner-4.jpg')} className='web-banner' alt='banner-2' />
                            <div className='banner-content padding-left-right'>
                                <div className='dot-img'>
                                    <img src={require('../assets/images/white-dot.png')} alt='white-dot' />
                                </div>
                                <h6 className='sub-heading'>OZETEL NETWORK PRIVATE LIMITED </h6>
                                <h1 className='heading'>"FAST CONNECTION,<br />
                                    BETTER NETWORK, BETTER RESPONSE"<br />
                                </h1>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

            <section className='service-section'>
                <div className='service-child-section'>
                    <img src={require('../assets/images/bg-tr.png')} alt='bg' className='bg-banner' />
                    <div className='header-title text-center'>
                        <h2 className='heading wow fadeInUp'>OUR SERVICES</h2>
                    </div>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                        }}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper marketing-slider"
                    >
                        <SwiperSlide>
                            <div className='card-box'>
                                <Link to='/services/telecom'>
                                    <div className='card-img'>
                                        <img src={require('../assets/images/values/service-4.jpg')} alt="ser-1" />
                                    </div>
                                    <h4>Telecom Services</h4>
                                    <p>We have the expertise and experience to install, support and manage in Telecom operations and applications.</p>
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-box'>
                                <Link to='/services/solar'>
                                    <div className='card-img'>
                                        <img src={require('../assets/images/values/service-2.jpg')} alt="ser-1" />
                                    </div>
                                    <h4>Solar Services</h4>
                                    <p>We are professionally managed company in the domain of solar and renewable energy solutions in India.</p>
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-box'>
                                <Link to='/services/wi-fi'>
                                    <div className='card-img'>
                                        <img src={require('../assets/images/values/service-1.jpg')} alt="ser-1" />
                                    </div>
                                    <h4>Wi-Fi Services</h4>
                                    <p>We are expert to create a perfect wi-fi network and offers other wi-fi services as well. </p>
                                </Link>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='card-box'>
                                <Link to='/services/web-development'>
                                    <div className='card-img'>
                                        <img src={require('../assets/images/values/service-5.jpg')} alt="ser-1" />
                                    </div>
                                    <h4>Web Development</h4>
                                    <p>Website development is our art – it's about envisioning, coding, and organizing... </p>
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-box'>
                                <Link to='/services/fiber-optics'>
                                    <div className='card-img'>
                                        <img src={require('../assets/images/services/fiber/fiber-1.jpg')} alt="ser-1" />
                                    </div>
                                    <h4>Fiber Optics</h4>
                                    <p>We specialize in designing and implementing high-performance fiber optic networks... </p>
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-box'>
                                <Link to='/services/digital-marketing'>
                                    <div className='card-img'>
                                        <img src={require('../assets/images/values/service-3.jpg')} alt="ser-1" />
                                    </div>
                                    <h4>Digital Marketing</h4>
                                    <p>We provide complete website solution and we have expert to provide this services. </p>
                                </Link>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className='explain-more wow fadeInUp'>
                        <Link to='/services' className='btn-class'>
                            All Services
                            <span>All Services</span>
                        </Link>
                    </div>
                </div>
            </section>

            <section className='top padding-left-right about-section-2'>
                <div className='header-title text-center'>
                    <h2 className='heading wow fadeInUp'>Our Expansive</h2>
                </div>
                <div className="row"><br />
                    <div className="col-md-4">
                        <div className="card" style={{ background: 'white' }}>
                            <div className="card-body">
                                <div className="card-icon">
                                    <img src={require('../assets/images/icons/5g.png')} alt="5g" style={{ height: '50px', width: '50px' }} />
                                </div>
                                <h5 className="card-title">5G</h5>
                                <p className="card-text">5G: made for innovation. With unprecedented speed and flexibility, our 5G carries more data with greater responsiveness and reliability than ever before, empowering next-gen applications, seamless connectivity, and groundbreaking advancements in technology.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card" style={{ background: 'white' }}>
                            <div className="card-body">
                                <div className="card-icon">
                                    <img src={require('../assets/images/icons/telecom.png')} alt="telecom" style={{ height: '50px', width: '50px' }} />
                                </div><br />
                                <h5 className="card-title">Telecom</h5>
                                <p className="card-text">Tailormade intelligence. Step into a world of smarter networks, delivering tomorrow’s business opportunities with precision, innovation, unparalleled efficiency, and transformative growth.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card" style={{ background: 'white' }}>
                            <div className="card-body">
                                <div className="card-icon">
                                    <img src={require('../assets/images/icons/support.png')} style={{ height: '50px', width: '50px' }} alt="support icons" />
                                </div>
                                <h5 className="card-title">Support</h5>
                                <p className="card-text">Facing problems with your telecom products? Call us anytime, and we will be on our toes to provide prompt, expert technical support, resolving issues swiftly to keep your services running smoothly and effectively. Our team is always ready.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FeaturedProject />

            <section className='bottom padding-left-right about-section-2'>
                <div className='container-fluid'>
                    <h3 className='sub-heading'>TRUSTED BY THE CLIENTS</h3>
                    <div className='brand-logo'>
                        <Swiper
                            slidesPerView={2.5}
                            spaceBetween={0}
                            navigation={false}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 0,
                                },
                                768: {
                                    slidesPerView: 5.8,
                                    spaceBetween: 0,
                                },
                                1024: {
                                    slidesPerView: 6,
                                    spaceBetween: 0,
                                },
                            }}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}

                            modules={[Autoplay]}

                            className="brand_logo-slider"
                        >
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/vedang-logo.png')} alt='vedang' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-ATS.jpg')} alt='ats' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-tejas.jpg')} alt='tejas' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-bbnl.jpg')} alt='delhi' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-bsnl.jpg')} alt='delhi' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-Ericsson-India.jpg')} alt='delhi' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-huber-shuner.jpg')} alt='delhi' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-mainfold.jpg')} alt='delhi' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/vedang-logo.png')} alt='vedang' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client/logo-ATS.jpg')} alt='ats' />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>
            <Contact />
        </>
    )
}
