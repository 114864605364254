import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css'
import './assets/js/script.js'
import './assets/fontIcon/css/all.min.css'
import Router from './route/Router';
import { ParallaxProvider } from 'react-scroll-parallax';

import { useEffect, useState } from 'react';

function App() {
 
  return (
    <ParallaxProvider>
      <div className="wrapper">
        {/* <ToastContainer /> */}
        
        <Router />
      </div>
    </ParallaxProvider>
  );
}

export default App;
