import React, { useEffect, useRef } from 'react'
import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
export default function OutofHome() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Fiber Optics Services</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item"><Link to="/services">Services</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Fiber Optics</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='service-detail-left-side'>
                                <h3 className='sub-heading'> OUR SERVICES</h3>
                                <h2 className='heading'>Fiber Optics</h2>
                                <p className=''>
                                    <strong>"We specialize in designing and implementing high-performance fiber optic networks, along with providing a range of fiber optic services to meet your connectivity needs."</strong>
                                </p>
                                <p>Experience the future of connectivity with <b>OZETEL NETWORK PRIVATE LIMITED</b> Fiber Optic Services. Specializing in high-speed, reliable fiber optic solutions, we offer customized installations for residential, commercial, and industrial clients. Our expert team ensures seamless integration from design to execution, leveraging state-of-the-art technology to optimize network performance and efficiency.
                                    Whether you need to enhance your network’s speed, reliability, or capacity, our comprehensive services include site assessment, installation, maintenance, and ongoing support. Join us in advancing your connectivity with cutting-edge fiber optic solutions that drive your business or home forward. Discover the advantages of fiber optics with <b>OZETEL NETWORK PRIVATE LIMITED</b> today.</p>
                                <div className='three-images-container'>
                                    <div className='first-img-box'>
                                        <img src={require('../../assets/images/services/fiber/fiber-2.jpg')} />
                                    </div>
                                    <div className='second-img-box'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/fiber/fiber-3.jpg')} />
                                        </div>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/fiber/fiber-4.jpg')} />
                                        </div>
                                    </div>
                                </div>
                                <p className=''>
                                    <strong>Fiber Optics Installation</strong>
                                </p>
                                <p>We have successfully installed over 5000 fiber optic networks in both rural and urban areas. Our services are currently active in 7 states.</p>

                                <p className=''>
                                    <strong>Fiber Optic Network Design</strong>
                                </p>
                                <p>We specialize in designing fiber optic networks tailored for various applications, including schools, colleges, cities, villages, hospitals, homes, and offices. Our designs ensure optimal performance and fast response times for all users.</p>

                                <p className=''>
                                    <strong>Support & Maintenance</strong>
                                </p>
                                <p>In addition to fiber optic installations, we offer comprehensive support and maintenance services. Our team is available to provide assistance throughout India, ensuring that your network remains reliable and efficient.</p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <ServiceInclude />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
