import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Header from "../component/Header";
import Footer from "../component/Footer";
import Service from "../pages/Service/Service";
import ServiceDetails from "../pages/Service/ServiceDetails";
import SolarServices from "../pages/Service/SolarServices";
import TelecomServices from "../pages/Service/TelecomServices";
import WiFiServices from "../pages/Service/WiFiServices";
import WebsiteDevelopment from "../pages/Service/WebsiteDevelopment";
import Client from "../pages/Client";
import Contact from "../pages/Contact";
import VisionAndMission from "../pages/VisionAndMission";
import Overview from "../pages/Overview";
import FiberOptics from '../pages/Service/FiberOptics';
import Career from "../pages/Career";

export default function Router() {
    const [loading, setLoading] = useState(true);
    const [headerData, setHeaderData] = useState([]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer); // Cleanup function to clear the timer
    }, []);


    return (
        <>
            <BrowserRouter>

                <Header setHeaderData={setHeaderData} />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/services" element={<Service />} />
                    <Route path="/services/solar" element={<SolarServices />} />
                    <Route path="/services/telecom" element={<TelecomServices />} />
                    <Route path="/services/wi-fi" element={<WiFiServices />} />
                    <Route path="/services/web-development" element={<WebsiteDevelopment />} />
                    <Route path="/services/fiber-optics" element={<FiberOptics />} />
                    <Route path="/service-detail" element={<ServiceDetails />} />
                    <Route path="/clients" element={<Client />} />
                    <Route path="/vision-mission" element={<VisionAndMission />} />
                    <Route path="/overview" element={<Overview />} />
                    <Route path="/contact-us" element={<Contact />} />
                    <Route path="/career" element={<Career />} />
                </Routes>
                <Footer headerData={headerData} />
            </BrowserRouter >
        </>
    );
}