
import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Pagination, Autoplay, Navigation, Keyboard } from 'swiper/modules';
// import WOW from 'wowjs';

import CountUp from 'react-countup';
import { Button, Label } from 'reactstrap';
import { Parallax, useParallax } from 'react-scroll-parallax';

import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Link } from 'react-router-dom';

export default function Career() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Career</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Career</li>
                    </ol>
                </nav>
            </section>

            <section className='top bottom padding-left-right about-section-1 '>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 order_2'>
                            <div className='left-section'>
                                <div className='bg_overly'></div>
                                <div className='top-section-box top-section-box-one'>
                                    <div className='circle-box first same_box'>
                                        <img src={require('../assets/images/team.jpg')} />
                                    </div>
                                    <div className='circle-box first same_box1 second'>
                                        <img src={require('../assets/images/team.jpg')} />
                                    </div>
                                </div>
                                <div className='top-section-box top-section-box-two'>

                                    <div className='circle-box first same_box1 three'>
                                        <img src={require('../assets/images/team.jpg')} />
                                    </div>
                                    <div className='circle-box first same_box'>
                                        <img src={require('../assets/images/team.jpg')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 order_1'>
                            <div className='right-section about_content'>
                                <h2 className='heading wow fadeInUp'>Join Our Team at OZETEL Network Private Limited.</h2>
                                <p className='paragraph wow fadeInUp'>Thank you for your interest to join the OZETEL Network Private Limited.
                                    Please provide the following information. This information will send our recruitment team and they will call you.</p>
                                <p className='paragraph p1 wow fadeInUp'>If you're passionate about marketing, thrive in a fast-paced environment, and are
                                    looking for a rewarding career where you can make a difference, we want to hear from you! Explore our current job openings
                                    below and take the next step towards an exciting career journey with OZETEL Network Private Limited.</p>
                                <p className='paragraph p1 wow fadeInUp'></p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
