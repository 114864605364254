import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap'
import ContactForm from '../component/contact';

export default function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <section className='inner-banner'>
                <h1 className='heading'>Contact Us</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Contact us</li>
                    </ol>
                </nav>
            </section>
            <ContactForm />
        </>
    )
}
