import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);



  return (
    <div>
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav className="navbar" onClick={e => e.stopPropagation()}>
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo" style={{ fontFamily: 'Algerian' }}>
            <img src={require('../assets/images/wiconnect.jpg')} alt="logo" />
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
              >
                Company
              </NavLink>
              <ul className="nav__submenu">
                <li className="nav__submenu-item">
                  <Link to="/overview">Overview</Link>
                </li>
                <li className="nav__submenu-item">
                  <Link to="/vision-mission">Vision & Mission </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/services"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Services
              </NavLink>

            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/clients"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Clients
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/career"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Career
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact-us"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Contact
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
      </nav>

      {/* <header>
        <nav>
          <div class="logo"><a href="#"> King</a></div>
          <label for="menubrop" class="bartoggle">≡</label>
          <input type="checkbox" id="menubrop" />
          <ul class="NavMenu">
            <li><a href="#">Home</a></li>
            <li><a href="#">About Us</a></li>
            <li><a href="javascript:void(0)"><label for="droplist1" class="toggle">Our Service</label></a>
              <input type="checkbox" id="droplist1" />
              <ul>
                <li><a href="#">Service A</a></li>
                <li><a href="#">Service B</a></li>
                <li>
                  <a href="javascript:void(0)"><label for="droplist2" class="toggle">Service C</label></a>
                  <input type="checkbox" id="droplist2" />
                  <ul>
                    <li><a href="#">Sub Service 1</a></li>
                    <li><a href="#">Sub Service 2</a></li>
                    <li><a href="#">Sub Service 3</a></li>
                    <li><a href="#">Sub Service 4</a></li>
                  </ul>
                </li>
                <li><a href="#">Service D</a></li>
              </ul>
            </li>
            <li><a href="#">Our Gallery</a></li>
            <li><a href="#">Contact Us</a></li>
            <li><a href="#">Enquiry</a></li>
          </ul>
        </nav>
      </header> */}
    </ div>
  );
}

export default NavBar;
