import React, { useEffect, useRef } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
export default function CreativeServices() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Web Development</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item"><Link to="/services">Services</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Web Development</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='service-detail-left-side'>
                                <h3 className='sub-heading'> OUR SERVICES</h3>
                                <h2 className='heading'>Web Development</h2>
                                <p className=''>
                                    <strong>Design solutions that capture attention and communicate brand messaging effectively.</strong>
                                </p>
                                <p>Website development is our art – it's about envisioning, coding, and organizing the symphony of layout, content, and functionality. We dance on both stages: the front-end, ensuring a seamless user experience, and the back-end, orchestrating server-side magic and databases. Armed with languages like HTML, CSS, and JavaScript,React.js we use frameworks and tools to create responsive, interactive, and dynamic online wonders. From informative sites to e-commerce realms and intricate web applications, we turn visions into virtual reality!</p>
                                <div className='three-images-container'>
                                    <div className='first-img-box'>
                                        <img src={require('../../assets/images/services/Creative-Services/1.png')} />
                                    </div>
                                    <div className='second-img-box'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/Creative-Services/2.jpg')} />
                                        </div>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/Creative-Services/3.jpg')} />
                                        </div>
                                    </div>
                                </div>
                                <p className=''>
                                    <strong>Static Website</strong>
                                </p>
                                <p>A static website in which we can not change content. Each page of a static website is created as a separate HTML file, and the content remains the same for every user who visits the site. These websites are simpler in terms of design and functionality compared to dynamic websites.</p>
                                <p className=''>
                                    <strong>Dynamic Website</strong>
                                </p>
                                <p>
                                    A dynamic website utilizes server-side scripting and databases to generate real-time content, responding dynamically to user interactions. Unlike static websites, dynamic sites feature content that adapts based on user input, preferences, or other variables. Technologies such as PHP, Node.js, and databases like MySQL,MongoDB are commonly employed to manage and deliver dynamic content efficiently. This approach ensures that users receive personalized experiences, updated information, and interactive features, enhancing engagement and usability. Dynamic websites are pivotal in modern web development for their ability to cater content dynamically, making them versatile and adaptive to evolving user needs and preferences.
                                </p>
                                <p className=''>
                                    <strong>Web Redesign</strong>
                                </p>
                                <p>Web redesign entails overhauling and improving the design, structure, and functionality of an existing website. The objective is to enhance visual appeal, user experience, and business outcomes. This process involves reassessing the site's layout, content, navigation, and potentially updating the underlying technology. By modernizing design elements and optimizing user interface (UI) and user experience (UX) features, web redesign aims to attract and engage visitors more effectively. Ultimately, the goal is to ensure the website aligns with current trends, meets user expectations, and supports business goals, resulting in improved performance and enhanced online presence. </p>
                                <p className=''>
                                    <strong>Ecommerce website </strong>
                                </p>
                                <p>An e-commerce website serves as a virtual platform where businesses can sell products or services directly to customers via the internet. It enables users to browse, select, and purchase items from an online store, equipped with essential features such as shopping carts, secure payment gateways, and order tracking systems. This digital marketplace facilitates seamless transactions, providing convenience and accessibility for customers to shop from anywhere at any time. E-commerce websites are crucial for businesses looking to expand their reach, enhance customer experience, and optimize sales through efficient online retail operations. </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <ServiceInclude />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
